<template>
  <button
    v-if="iconed"
    ref="btn"
    :disabled="isButtonActionDisabled"
    :class="[
      {
        'z-50': onTop,
        'is-clicked': isClicked,
      },
      size,
    ]"
    :type="type"
    class="button-iconed"
    @click="onClickV"
  >
    <AppHoverTooltip
      :x-position="tooltipXPosition"
      :y-position="tooltipYPosition"
      :disabled="!(($slots['default'] && iconed) || $slots['tooltip']) || disabled"
      class="size-full min-w-min"
    >
      <template #tooltip>
        <slot name="default" />
        <slot name="tooltip" />
      </template>

      <template #default>
        <component
          :is="component"
          :key="component"
          :[prop]="to"
          :target="newTab ? '_blank' : undefined"
          :class="[textColorClass, contentClass, `component-${component}`, size, { loading }]"
          class="icon-container"
          @click="onClickContent"
        >
          <div class="icon">
            <slot name="icon">
              <AppIcon
                v-if="loading"
                :icon="config.icons.LOADING"
                spin
                :size="iconSize"
              />
              <AppIcon
                v-else
                :icon="icon"
                :size="iconSize"
              />
            </slot>
          </div>
        </component>
      </template>
    </AppHoverTooltip>
  </button>

  <button
    v-else
    ref="btn"
    :disabled="isButtonActionDisabled"
    :class="[
      {
        'z-50': onTop,
        'w-full': fullWidth,
        'is-clicked': isClicked,
        loading,
        outlined,
        vertical,
      },
      size,
    ]"
    :type="type"
    class="button"
    @click="onClickV"
  >
    <AppHoverTooltip
      :x-position="tooltipXPosition"
      :y-position="tooltipYPosition"
      :disabled="!$slots['tooltip'] || disabled"
      class="button-tooltip-container size-full"
    >
      <template
        v-if="$slots['tooltip']"
        #tooltip
      >
        <slot name="tooltip" />
      </template>

      <template #default>
        <component
          :is="component"
          :key="component"
          :[prop]="to"
          :target="newTab ? '_blank' : undefined"
          class="button-content"
          :class="[
            textColorClass,
            contentWrapperClass,
            size,
            `component-${component}`,
            { loading },
          ]"
          @click="onClickContent"
        >
          <div
            v-if="outlined"
            class="button-content-outlined-bg"
          >
            <div class="button-content-outlined-bg-gradient bg-fixed bg-main-gradient" />
          </div>
          <AppIcon
            v-if="loading"
            class="loading-icon absolute m-auto"
            :icon="config.icons.LOADING"
            spin
          />
          <AppIcon
            v-if="prependIcon"
            :icon="prependIcon"
            :size="iconSize"
            :class="prependIconClass"
            v-bind="prependIconProps"
          />
          <slot name="prepend" />
          <div
            class="button-content-center"
            :class="[contentClass, { vertical, left }]"
          >
            <slot name="icon">
              <AppIcon
                v-if="icon"
                :icon="icon"
                :size="iconSize"
                v-bind="iconProps"
                class="main-icon"
              />
            </slot>
            <slot name="default" />
          </div>
          <slot name="append" />
          <AppIcon
            v-if="appendIcon"
            :icon="appendIcon"
            :size="iconSize"
            :class="appendIconClass"
            v-bind="appendIconProps"
          />
        </component>
      </template>
    </AppHoverTooltip>
  </button>
</template>

<script setup>
import throttle from 'lodash-es/throttle'
import { computed, ref, toRef } from 'vue'
import AppIcon from '@/components/App/AppIcon'
import AppHoverTooltip from '@/components/App/Menu/AppHoverTooltip'
import useRouterComponent from '@/composables/useRouterComponent'
import config from '@/config'

const props = defineProps({
  tooltipXPosition: { type: String, default: 'right' },
  tooltipYPosition: { type: String, default: 'top' },
  type: { type: String, default: 'button' },
  onTop: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  left: { type: Boolean, default: false },
  outlined: { type: Boolean, default: false },
  vertical: { type: Boolean, default: false },
  size: {
    type: String,
    default: 'sm',
    validator: v => ['xs', 'sm', 'md'].includes(v),
  },
  disabled: { type: Boolean, default: false },
  iconed: { type: Boolean, default: false },
  icon: { type: String, default: '' },
  iconProps: { type: Object },
  iconSize: { type: String, default: 'sm' },
  prependIcon: { type: String, default: '' },
  prependIconClass: { type: [String, Object, Array], default: '' },
  prependIconProps: { type: Object },
  appendIcon: { type: String, default: '' },
  appendIconClass: { type: [String, Object, Array], default: '' },
  appendIconProps: { type: Object },
  to: { type: [String, Object], default: '' },
  textColorClass: { type: String, default: 'text-main' },
  contentClass: { type: [String, Object, Array], default: '' },
  contentWrapperClass: { type: [String, Object, Array], default: '' },
  fullWidth: { type: Boolean, default: false },
  newTab: Boolean,
  onClickContent: Function,
  throttleDelay: { type: Number, default: 400 },
})
const emit = defineEmits(['click-content', 'click'])

const btn = ref(null)

const disabled = toRef(props, 'disabled')
const loading = toRef(props, 'loading')
const to = toRef(props, 'to')
const { component, prop } = useRouterComponent({ to, disabled, loading })

const isClicked = ref(false)
const isButtonActionDisabled = computed(() => disabled.value || loading.value || isClicked.value)
const onClickV = throttle(
  ev => {
    isClicked.value = true
    emit('click', ev)
    setTimeout(() => (isClicked.value = false), props.throttleDelay)
  },
  props.throttleDelay,
  { trailing: false }
)
</script>

<style lang="sass" scoped>
.button-iconed
  @apply flex flex-col justify-center items-center rounded-button
  @apply transition duration-300 ease-in-out
  @apply border-2 border-transparent border-solid
  .icon-container
    @apply flex flex-col justify-center items-center aspect-square transition
    .icon
      @apply flex flex-col justify-center items-center
  &:not(.absolute)
    @apply relative
  &.xs .icon
    @apply m-[2px]
  &.sm .icon
    @apply mx-1
  &.md .icon
    @apply m-[6px]
  &:disabled .icon
    @apply brightness-0 contrast-0
    @apply text-disabled
  &:not(:disabled):not(.loading):hover
    @apply border-accent

.button
  @apply flex flex-row items-center justify-center gap-2 no-underline
  @apply transition duration-300 ease-in-out
  @apply rounded-button overflow-hidden

button.button
  &:focus-visible
    outline: none
  .xs
    @apply py-0 px-2
  .md
    @apply py-3.5 px-4
  .sm
    @apply py-2 px-4
  .button-content
    @apply min-w-min
  &:not(.loading):not(:disabled):not(.outlined)
    .button-tooltip-container
      @apply transition duration-300 rounded-button
    &:hover
      .button-tooltip-container
        @apply bg-gray-600 mix-blend-luminosity
    &:focus
      @apply ring-2 ring-accent

button.button:disabled
  @apply cursor-auto bg-disabled
  .button-content-center
    @apply text-disabled flex-none

button.button.outlined
  & > .button-tooltip-container
    @apply border-2 border-solid rounded-button overflow-hidden
  &:not(:disabled) > .button-tooltip-container
    @apply border-secondary
  &:disabled > .button-tooltip-container
    @apply border-disabled
  .button-content > *
    @apply z-10
  .button-content-outlined-bg
    @apply rounded-button overflow-hidden
    @apply absolute top-0 left-0 w-full h-full z-0
  &:not(:disabled)
    &:hover, &.is-clicked
      .button-content-outlined-bg-gradient
        @apply scale-x-100
  .button-content-outlined-bg-gradient
    @apply scale-x-0 transition duration-300 ease-in-out origin-left w-full h-full

button.button.is-clicked:not(:disabled):not(.loading)
  @apply brightness-75 contrast-100 transition-none

.button-content
  @apply h-full flex flex-row items-center justify-center gap-2 no-underline relative
  @apply font-bold text-base capitalize
  &.loading *:not(.loading-icon)
    @apply text-transparent
  &.small
    @apply font-normal text-sm
.button-content-center
  @apply grow flex flex-row self-stretch items-center no-underline
  &.left
    @apply justify-start
  &:not(.left)
    @apply justify-center
.button-content-center:not(.vertical)
  @apply gap-2
.button-content-center.vertical
  @apply flex-col gap-2
</style>
